<template>
  <div :class="classContainer">
    <div class="playground-creation-container">
      <description
        :btn-loading="btnLoading"
        @on:step-0:validated="validatePlaygroundForm"
      />
      <div class="mt-2 container-fluid">
        <b-card>
          <div class="row-style">
            <d-body-nav-bar
              :tabs="[]"
              position="float-right"
              title="views.playground.steps.step-1.informations.general.title"
              title-class="topnav-title-red-light"
            />
          </div>
          <general-form
            :playground="playground"
            :validator="validator"
            @step-0:general-informations:validated="onPlaygroundFormValidated()"
          />
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
import {postClubPlayground, putClubPlayground} from "@api/doinsport/services/playground/playground.api";
import Playground from "@/classes/doinsport/Playground";

export default {
  data: () => ({
    componentKey: 0,
    btnLoading: false,
    validator: false,
  }),
  components: {
    Description: () => import('./Description'),
    GeneralForm: () => import('@form/playground/new/step-1/GeneralForm')
  },
  props: {
    playground: {
      type: Object,
      default: this
    },
    classContainer: {
      type: String,
      default: ''
    }
  },
  methods: {
    validatePlaygroundForm() {
      this.validator = !this.validator;
    },
    checkCancellationConditionType() {
      switch (this.playground.bookingCancellationConditionType) {
        case 'null':
          this.playground.bookingCancellationConditionType = null;
          this.playground.bookingCancellationConditionCustomHours = null;
          break;
        case 'soft':
          this.playground.bookingCancellationConditionCustomHours = null;
          break;
        case 'strict':
          this.playground.bookingCancellationConditionCustomHours = null;
          break;
        default:
          break
      }
    },
    onPlaygroundFormValidated() {
      this.checkCancellationConditionType();

      if (!this.btnLoading) {
        this.btnLoading = true;
        this.createOrUpdatePlayground(new Playground(this.playground, {toEntity: true}))
          .then((resp) => {
            this.btnLoading = false;
            this.$router.push({name: 'playground_config-step1', params: {id: resp.data.id}});
          })
          .finally(() => {
            this.btnLoading = false;
          })
        ;
      }
    },
    createOrUpdatePlayground(serializedPlayground) {
      return this.playground.id === null ? postClubPlayground(serializedPlayground) : putClubPlayground(this.playground.id, serializedPlayground);
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/_b-card.scss";

.playground-creation-container {
  background-color: #F9FAFA;
}
</style>
